import * as PIXI from "pixi.js-legacy";

export default class BackgroundText extends PIXI.Container {
  constructor() {
    super();
    this._textValue = "";
    this._styleValue = new PIXI.TextStyle({});
    this._horizontalPadding = 0;
    this._backgroundColor = 0xffffff;
    this._backgroundOpacity = 1;
    this._borderRadius = 0;

    this._backgrounds = [];
    this.backgroundContainer = new PIXI.Container();
    this.addChild(this.backgroundContainer);

    this._textDisplay = new PIXI.Text("");
    this.addChild(this._textDisplay);
  }

  set borderRadius(value) {
    this._borderRadius = value;
    this.updateLayout();
  }

  get borderRadius() {
    return this._borderRadius;
  }

  set backgroundColor(value) {
    this._backgroundColor = value;
    this.updateLayout();
  }

  get backgroundColor() {
    return this._backgroundColor;
  }

  set backgroundOpacity(value) {
    this._backgroundOpacity = value;
    this.updateLayout();
  }

  set horizontalPadding(value) {
    this._horizontalPadding = value;
    this.updateLayout();
  }

  get horizontalPadding() {
    return this._horizontalPadding;
  }

  get text() {
    return this._textValue;
  }

  set text(value) {
    this._textValue = value.toString();
    this._textDisplay.text = value.toString();
    this.updateLayout();
  }

  get style() {
    return this._styleValue;
  }

  set style(value) {
    this._styleValue = value;
    this._textDisplay.style = value;
    this.updateLayout();
  }

  set resolution(value) {
    this._textDisplay.resolution = value;
  }

  get resolution() {
    return this._textDisplay.resolution;
  }

  updateLayout() {
    this._backgrounds.forEach((bg) => {
      this.backgroundContainer.removeChild(bg);
    });
    this._backgrounds = [];

    const textMetrics = PIXI.TextMetrics.measureText(
      this._textValue,
      this._styleValue,
    );

    textMetrics.lineWidths.forEach((width, index) => {
      const rect = new PIXI.Graphics();
      rect.beginFill(this._backgroundColor, this._backgroundOpacity);
      rect.drawRoundedRect(
        0,
        0,
        width + this._horizontalPadding * 2,
        textMetrics.lineHeight - this._styleValue.leading,
        this._borderRadius,
      );

      rect.y = textMetrics.lineHeight * index;

      switch (this._styleValue.align) {
        case "left":
          rect.x = -this._horizontalPadding;
          break;
        case "center":
          rect.x = this._textDisplay.width / 2 - rect.width / 2;
          break;
        case "right":
          rect.x =
            this._textDisplay.width - rect.width + this._horizontalPadding;
          break;
      }

      this.backgroundContainer.addChild(rect);
      this._backgrounds.push(rect);
    });
  }
}
