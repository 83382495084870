import React, { Component } from "react";
import { Provider } from "react-redux";
import { store } from "./redux";
import I18n from "redux-i18n";
import Icon from "./components/Icon/Icon";
import IconTypes from "./types/IconTypes";
import Router from "./Routes/Router";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <I18n translations={{}} useReducer>
          <div className="App">
            <Router />
            <canvas id="canvas"></canvas>
          </div>
        </I18n>
      </Provider>
    );
  }
}

export default App;
