import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  text: "",
  styleIndex: 0,
});

export const actions = {
  setText: createAction("set text", (text) => ({ text })),
  setStyleIndex: createAction("set style index", (styleIndex) => ({
    styleIndex,
  })),
};

export const reducer = createReducer(
  {
    [actions.setText]: (state, { text }) => state.merge({ text }),
    [actions.setStyleIndex]: (state, { styleIndex }) =>
      state.merge({ styleIndex }),
  },
  initialState,
);

export const selectors = {
  text: (state) => state.tagEditor.text,
  styleIndex: (state) => state.tagEditor.styleIndex,
};
