import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  initialBound: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  bound: {
    x: 100,
    y: 100,
    width: 100,
    height: 100,
    scale: 1,
  },
  isInEditMode: false,
  rotationIndex: 0,
});

export const actions = {
  setBound: createAction("set bound", (x, y, width, height, scale) => ({
    x,
    y,
    width,
    height,
    scale,
  })),
  setEditMode: createAction("set edit mode", (isInEditMode) => ({
    isInEditMode,
  })),
  setInitialBound: createAction("set initial bound", (x, y, width, height) => ({
    x,
    y,
    width,
    height,
  })),
  setRotationIndex: createAction("set rotation index", (rotationIndex) => ({
    rotationIndex,
  })),
};

export const reducer = createReducer(
  {
    [actions.setBound]: (state, { x, y, width, height, scale }) =>
      state.merge({ bound: { x, y, width, height, scale } }),
    [actions.setInitialBound]: (state, { x, y, width, height, scale }) =>
      state.merge({
        initialBound: { x, y, width, height, scale },
        bound: { x, y, width, height, scale: 1 },
      }),
    [actions.setEditMode]: (state, { isInEditMode }) =>
      state.merge({ isInEditMode }),
    [actions.setRotationIndex]: (state, { rotationIndex }) =>
      state.merge({ rotationIndex }),
  },
  initialState,
);

export const selectors = {
  bound: (state) => state.cropEditor.bound,
  initialBound: (state) => state.cropEditor.initialBound,
  isInEditMode: (state) => state.cropEditor.isInEditMode,
  rotationIndex: (state) => state.cropEditor.rotationIndex,
};
