import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  appStarted: false,
  keyboardHeight: 0,
});

export const actions = {
  startApp: createAction("startApp"),
  setKeyboardHeight: createAction("set keyboard height", (keyboardHeight) => ({
    keyboardHeight,
  })),
};

export const reducer = createReducer(
  {
    [actions.startApp]: (state) => state.merge({ appStarted: true }),
    [actions.setKeyboardHeight]: (state, { keyboardHeight }) =>
      state.merge({ keyboardHeight: keyboardHeight }),
  },
  initialState,
);

export const selectors = {
  appStarted: (state) => state.app.appStarted,
  keyboardHeight: (state) => state.app.keyboardHeight,
};
