import colors from "./colors";
import backgrounds from "./backgrounds";
import aligns from "./aligns";
import fonts from "./fonts";
import tags from "./tags";
import text from "./text";

export default {
  colors,
  backgrounds,
  aligns,
  fonts,
  tags,
  text,
};
