import { all, fork, call } from "redux-saga/effects";
import initSaga from "./initSaga";
import AppSagas from "./app/sagas";
import TranslationSagas from "./translation/sagas";
import PostMessageSagas from "./postMessage/sagas";

function* loop() {
  yield all([TranslationSagas.loop(), PostMessageSagas.loop()]);
  return null;
}

export default function* rootSaga() {
  yield fork(loop);
  yield call(initSaga);
}
