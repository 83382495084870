import React from "react";
import TextToolbar from "./partials/TextToolbar/TextToolbar";
import DrawToolbar from "./partials/DrawToolbar/DrawToolbar";
import ManipulationToolbar from "./partials/ManipulationToolbar/ManipulationToolbar";
import CropToolbar from "./partials/CropToolbar/CropToolbar";
import TagToolbar from "./partials/TagToolbar/TagToolbar";
import { connect } from "react-redux";
import { selectors as ToolsSelectors } from "./../../redux/tools/redux";

class ContextToolbar extends React.PureComponent {
  render() {
    return (
      <div className="context-toolbar">
        {this.props.primaryTool === "text" && (
          <TextToolbar onSave={this.props.onSave} onQuit={this.props.onQuit} />
        )}
        {this.props.primaryTool === "draw" && (
          <DrawToolbar onSave={this.props.onSave} onQuit={this.props.onQuit} />
        )}
        {this.props.primaryTool === "manipulation" && (
          <ManipulationToolbar
            onSave={this.props.onSave}
            onQuit={this.props.onQuit}
          />
        )}
        {this.props.primaryTool === "crop" && (
          <CropToolbar onSave={this.props.onSave} onQuit={this.props.onQuit} />
        )}
        {this.props.primaryTool === "tag" && <TagToolbar />}
      </div>
    );
  }
}

const mapSelectors = (state) => ({
  primaryTool: ToolsSelectors.primaryTool(state),
});

export default connect(mapSelectors)(ContextToolbar);
