export default [
  {
    name: "Sunrise",
    style: {
      fontFamily: "Arial",
      fontSize: 36,
      fill: ["#ffab00", "#d44b00"],
    },
    background: {
      radius: 10,
      opacity: 1,
      fill: 0xffffff,
    },
  },

  {
    name: "Contrasted",
    style: {
      fontFamily: "Arial",
      fontSize: 36,
      fill: "#FFFFFF",
    },
    background: {
      radius: 5,
      opacity: 1,
      fill: 0x000000,
    },
  },

  {
    name: "Neon",
    style: {
      fontFamily: "Times New Roman",
      fontSize: 36,
      fill: "#ff00a7",
      dropShadow: true,
      dropShadowColor: "#f7a3da",
      dropShadowBlur: 20,
      dropShadowAngle: 0,
      dropShadowDistance: 2,
    },
    background: {
      radius: 20,
      opacity: 0.7,
      fill: 0x000000,
    },
  },
];
