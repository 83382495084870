import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  text: "",
  alignIndex: 0,
  colorIndex: 0,
  styleIndex: 0,
  //backgroundIndex:0,
  fontIndex: 0,
});

export const actions = {
  setText: createAction("set text", (text) => ({ text })),
  setAlignIndex: createAction("set alignIndex", (alignIndex) => ({
    alignIndex,
  })),
  setColorIndex: createAction("set color index", (colorIndex) => ({
    colorIndex,
  })),
  setStyleIndex: createAction("set style index", (styleIndex) => ({
    styleIndex,
  })),
  //setBackgroundIndex: createAction('set background index', backgroundIndex => ({backgroundIndex})),
  setFontIndex: createAction("set font index", (fontIndex) => ({ fontIndex })),
};

export const reducer = createReducer(
  {
    [actions.setText]: (state, { text }) => state.merge({ text }),
    [actions.setAlignIndex]: (state, { alignIndex }) =>
      state.merge({ alignIndex }),
    [actions.setColorIndex]: (state, { colorIndex }) =>
      state.merge({ colorIndex }),
    [actions.setStyleIndex]: (state, { styleIndex }) =>
      state.merge({ styleIndex }),
    // [actions.setFontIndex]: (state, {fontIndex}) => state.merge({fontIndex}),
  },
  initialState,
);

export const selectors = {
  text: (state) => state.textEditor.text,
  alignIndex: (state) => state.textEditor.alignIndex,
  colorIndex: (state) => state.textEditor.colorIndex,
  styleIndex: (state) => state.textEditor.styleIndex,
  fontIndex: (state) => state.textEditor.fontIndex,
};
