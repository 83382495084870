export default [
  {
    fontFamily: "Arial",
    fontSize: 36,
    fontWeight: "bold",
    fill: "#000000", // gradient
    stroke: "#FFFFFF",
    strokeThickness: 0,
    leading: 10,
  },
  {
    fontFamily: "Times New Roman",
    fontSize: 36,
    fontWeight: "bold",
    fill: "#FFFFFF", // gradient
  },
  {
    fontFamily: "Arial",
    fontSize: 36,
    fontStyle: "italic",
    fontWeight: "bold",
    fill: ["#ffffff", "#00ff99"], // gradient
    stroke: "#4a1850",
    strokeThickness: 5,
    dropShadow: true,
    dropShadowColor: "#000000",
    dropShadowBlur: 4,
    dropShadowAngle: Math.PI / 6,
    dropShadowDistance: 6,
  },
  {
    fontFamily: "Arial",
    fontSize: 36,
    fontWeight: "bold",
    fill: "#ff9200", // gradient
    dropShadow: true,
    dropShadowColor: "#ffb200",
    dropShadowBlur: 4,
    dropShadowAngle: Math.PI / 6,
    dropShadowDistance: 0,
  },
];
