import React from "react";
import PixiApp from "../../../../PixiApp";
import * as PIXI from "pixi.js-legacy";
import { connect } from "react-redux";
import {
  actions as ToolsActions,
  selectors as ToolsSelectors,
} from "../../../../redux/tools/redux";
import {
  actions as TagEditorActions,
  selectors as TagEditorSelectors,
} from "../../../../redux/tagEditor/redux";
import {
  actions as DisplayActions,
  selectors as DisplaySelectors,
} from "../../../../redux/display/redux";
import textStyles from "./textStyles";
import Icon from "../../../Icon/Icon";
import IconButton from "../../../IconButton/IconButton";
import IconTypes from "../../../Icon/Types";
import BackgroundText from "../../../../../pixi/BackgroundText";
import config from "../../../../config";

class TagToolbar extends React.PureComponent {
  updateStyle = () => {
    this.preview.style = new PIXI.TextStyle({
      ...config.tags[this.props.styleIndex].style,
      wordWrap: true,
      wordWrapWidth: window.innerWidth - 40,
      align: "center",
    });
    this.preview.backgroundColor =
      config.tags[this.props.styleIndex].background.fill;
    this.preview.backgroundOpacity =
      config.tags[this.props.styleIndex].background.opacity;
    this.preview.borderRadius =
      config.tags[this.props.styleIndex].background.radius;
  };

  componentDidMount() {
    this.background = new PIXI.Graphics();
    this.background.beginFill(0x000000, 0.7);
    this.background.drawRect(0, 0, window.innerWidth, window.innerHeight);
    this.background.endFill();
    this.background.interactive = true;
    this.background.zIndex = 2;
    PixiApp.stage.addChild(this.background);

    this.preview = null;
    this.initialTransform = null;
    if (this.props.editingDisplayId) {
      this.preview = PixiApp.getObject(this.props.editingDisplayId);
      this.initialTransform = {
        x: this.preview.x,
        y: this.preview.y,
        rotation: this.preview.rotation,
        scale: this.preview.scale.x,
      };
    } else {
      this.preview = new BackgroundText();
      this.preview.text = "#";
      this.preview.horizontalPadding = 10;
      this.preview.breakWords = true;
    }

    //this.preview.shader.
    this.preview.interactive = true; //allow manipulation
    this.preview.zIndex = 3;
    this.preview.scale.x = 1;
    this.preview.scale.y = 1;
    this.preview.resolution = this.preview.scale.x * window.devicePixelRatio;
    this.preview.pivot.x = this.preview.width / 2;
    this.preview.pivot.y = this.preview.height / 2;
    this.preview.x = window.innerWidth / 2;
    this.preview.y = PixiApp.bound.top + 15 + this.preview.height / 2;

    this.preview.rotation = 0;
    this.updateStyle();

    this.handleFocusLost();
    this._input.addEventListener("blur", this.handleFocusLost);
    this._input.addEventListener("focusout", this.handleFocusLost);

    PixiApp.stage.addChild(this.preview);
  }

  componentWillUnmount() {
    PixiApp.stage.removeChild(this.background);
    this._input.removeEventListener("blur", this.handleFocusLost);
    this._input.removeEventListener("focusout", this.handleFocusLost);
    if (this.preview) PixiApp.stage.removeChild(this.preview);
  }

  handleChangeText = (event) => {
    this.props.setText(event.target.value);
    this.preview.text = "#" + event.target.value;
    this.preview.pivot.x = this.preview.width / 2;
    this.preview.pivot.y = this.preview.height / 2;
    this.preview.y = PixiApp.bound.top + 15 + this.preview.height / 2;
  };

  handleValidate = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (this.props.text === "") {
      PixiApp.stage.removeChild(this.preview);
      this.preview = null;
      this.props.clearPrimaryTool();
      return;
    }

    const displayId = PixiApp.registerObject(this.preview);
    this.props.addItem(displayId, {
      toolbar: "tag",
      styleIndex: this.props.styleIndex,
      allowManipulation: true,
    });
    this.preview.zIndex = 1;

    if (this.initialTransform) {
      this.preview.x = this.initialTransform.x;
      this.preview.y = this.initialTransform.y;
      this.preview.rotation = this.initialTransform.rotation;
      this.preview.scale.x = this.initialTransform.scale;
      this.preview.scale.y = this.initialTransform.scale;
      this.preview.pivot.x = (this.preview.width / this.preview.scale.x) * 0.5;
      this.preview.pivot.y = (this.preview.height / this.preview.scale.y) * 0.5;
      this.preview.resolution = this.preview.scale.x; //* window.devicePixelRatio
      this.preview.dirty = true;
    }

    this.preview = null;
    this.props.clearPrimaryTool();
  };

  handleChangeStyle = () => {
    let index = this.props.styleIndex;
    index++;
    if (index > config.tags.length - 1) index = 0;
    this.props.setStyleIndex(index);

    setTimeout(() => {
      this.updateStyle();
    }, 0);
  };

  handleFocusLost = () => {
    this._input.focus();
    if (typeof this._input.selectionStart == "number") {
      this._input.selectionStart = this._input.selectionEnd =
        this._input.value.length;
    } else if (typeof this._input.createTextRange != "undefined") {
      this._input.focus();
      var range = this._input.createTextRange();
      range.collapse(false);
      range.select();
    }
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleValidate}>
          <input
            className="tag-hidden-field"
            ref={(c) => (this._input = c)}
            autoFocus
            value={this.props.text}
            onChange={this.handleChangeText}
          />
        </form>
        <div className="top-toolbar">
          <div className="style-list" onClick={this.handleChangeStyle}>
            {config.tags[this.props.styleIndex].name}
          </div>
          <div className="right-side">
            <IconButton
              icon={IconTypes.VALIDATE}
              onClick={this.handleValidate}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapSelectors = (state) => ({
  text: TagEditorSelectors.text(state),
  styleIndex: TagEditorSelectors.styleIndex(state),
  editingDisplayId: ToolsSelectors.editingDisplayId(state),
});

const mapActions = (dispatch) => ({
  setText: (text) => dispatch(TagEditorActions.setText(text)),
  setStyleIndex: (index) => dispatch(TagEditorActions.setStyleIndex(index)),
  clearPrimaryTool: () => dispatch(ToolsActions.clearPrimaryTool()),
  addItem: (id, meta) => dispatch(DisplayActions.addItem(id, meta)),
});

export default connect(mapSelectors, mapActions)(TagToolbar);
