import React from "react";
import PixiApp from "../../../../PixiApp";
import * as PIXI from "pixi.js-legacy";
import { connect } from "react-redux";
import {
  actions as ToolsActions,
  selectors as ToolsSelectors,
} from "../../../../redux/tools/redux";
import {
  actions as TextEditorActions,
  selectors as TextEditorSelectors,
} from "../../../../redux/textEditor/redux";
import {
  actions as DisplayActions,
  selectors as DisplaySelectors,
} from "../../../../redux/display/redux";
import { selectors as CropEditorSelectors } from "../../../../redux/cropEditor/redux";
import { selectors as AppSelectors } from "../../../../redux/app/redux";
import textStyles from "./textStyles";
import BackgroundText from "../../../../../pixi/BackgroundText";
import Icon from "../../../Icon/Icon";
import IconButton from "../../../IconButton/IconButton";
import IconTypes from "../../../Icon/Types";
import ColorSwiper from "../../../ColorSwiper/ColorSwiper";
import * as utils from "../../../../pages/EditorPage/utils";

import config from "../../../../config";

const TEXT_CENTER_Y = 225;

const toColorNumber = (v) => {
  if (typeof v === "string" && v.indexOf("#") === 0) {
    return parseInt(v.replace("#", "0x"));
  }
  return v;
};

const mapStyles = (o, replace) => {
  let mapped = { ...o };
  for (var i in mapped) {
    if (typeof mapped[i] === "string" && replace[mapped[i]] !== undefined) {
      mapped[i] = replace[mapped[i]];
    }
  }
  return mapped;
};

class TextToolbar extends React.PureComponent {
  updateStyle = () => {
    const selectedColor = config.colors[this.props.colorIndex];
    const stylesMap = {
      $colorStyle: selectedColor,
    };

    const style = config.text[this.props.styleIndex];
    const textStyles = mapStyles(
      config.text[this.props.styleIndex].style,
      stylesMap,
    );
    const backgroundStyles = mapStyles(
      config.text[this.props.styleIndex].background,
      stylesMap,
    );
    this.preview.style = new PIXI.TextStyle({
      //fill: config.colors[this.props.colorIndex],
      ...textStyles,
      align: config.aligns[this.props.alignIndex].align,
      wordWrap: true,
      wordWrapWidth: window.innerWidth - 40,
      //...config.fonts[this.props.fontIndex].style
    });

    this.preview.backgroundColor = toColorNumber(backgroundStyles.fill);
    this.preview.backgroundOpacity = backgroundStyles.opacity;
    this.preview.borderRadius = backgroundStyles.radius;
    this.handleFocusLost();
  };

  componentDidMount() {
    this.background = new PIXI.Graphics();
    this.background.beginFill(0x000000, 0.5);
    this.background.drawRect(0, 0, window.innerWidth, window.innerHeight);
    this.background.endFill();
    this.background.interactive = true;
    this.background.zIndex = 2;
    this.background.on("pointerdown", () => {
      this.handleValidate();
    });
    PixiApp.app.stage.addChild(this.background);

    this.handleFocusLost();
    this._input.addEventListener("blur", this.handleFocusLost);
    this._input.addEventListener("focusout", this.handleFocusLost);
    this._input.addEventListener("change", this.handleValidate);
    /*
console.log('mounted')
   const evts = ['onabort', 'onautocomplete', 'onautocompleteerror', 'oncancel', 'oncanplay', 'oncanplaythrough', 'onchange', 'onclick', 'onclose', 'oncontextmenu', 'oncuechange', 'ondblclick', 'ondrag', 'ondragend', 'ondragenter', 'ondragexit', 'ondragleave', 'ondragover', 'ondragstart', 'ondrop', 'ondurationchange', 'onemptied', 'onended', 'onerror', 'onfocus', 'oninput', 'oninvalid', 'onkeydown', 'onkeypress', 'onkeyup', 'onload', 'onloadeddata', 'onloadedmetadata', 'onloadstart', 'onmousedown', 'onmouseenter', 'onmouseleave', 'onmousemove', 'onmouseout', 'onmouseover', 'onmouseup', 'onmousewheel', 'onpause', 'onplay', 'onplaying', 'onprogress', 'onratechange', 'onreset', 'onresize', 'onscroll', 'onseeked', 'onseeking', 'onselect', 'onshow', 'onsort', 'onstalled', 'onsubmit', 'onsuspend', 'ontimeupdate', 'ontoggle', 'onvolumechange', 'onwaiting']
    evts.forEach(e => {
      const name = e.substr(2)
      this._input.addEventListener(name, ()=>{
        console.log('trigger event', name)
      })

    })
    */

    this.preview = null;
    this.initialTransform = null;
    if (this.props.editingDisplayId) {
      this.preview = PixiApp.getObject(this.props.editingDisplayId);
      this.props.setText(this.preview.text);
      //this.props.setColorIndex(this.preview)
      this.initialTransform = {
        x: this.preview.x,
        y: this.preview.y,
        rotation: this.preview.rotation,
        scale: this.preview.scale.x,
      };
      //console.log('editing item', this.props.item)
      this.props.setColorIndex(this.props.item.colorIndex);
      this.props.setStyleIndex(this.props.item.styleIndex);
    } else {
      this.preview = new BackgroundText(this.props.text);
      this.preview.horizontalPadding = 10;
      this.preview.breakWords = true;
    }

    PixiApp.app.stage.addChild(this.preview);

    //this.preview.shader.
    this.preview.interactive = true; //allow manipulation
    this.preview.zIndex = 3;
    this.preview.scale.x = 1;
    this.preview.scale.y = 1;
    this.preview.resolution = this.preview.scale.x * window.devicePixelRatio;
    this.preview.pivot.x = this.preview.width / 2;
    this.preview.pivot.y = 0; //this.preview.height /2

    const [previewX, previewY] = utils.screenToCrop(
      this.props.cropBound,
      window.innerWidth / 2,
      PixiApp.bound.top + 15 + this.preview.height,
    );

    //this.preview.x = previewX//window.innerWidth/2;
    //this.preview.y = previewY//PixiApp.bound.top + 15 + this.preview.height /2

    this.preview.x = window.innerWidth / 2;
    this.preview.y = TEXT_CENTER_Y - this.preview.height / 2;

    this.preview.rotation = 0;
    this.updateStyle();
  }

  handleFocusLost = () => {
    console.log("focus lost");
    this._input.focus();
    if (typeof this._input.selectionStart == "number") {
      this._input.selectionStart = this._input.selectionEnd =
        this._input.value.length;
    } else if (typeof this._input.createTextRange != "undefined") {
      this._input.focus();
      var range = this._input.createTextRange();
      range.collapse(false);
      range.select();
    }
  };

  handleKeyDown = (event) => {
    console.log("key down", event.keyCode);
  };

  componentWillUnmount() {
    this._input.removeEventListener("blur", this.handleFocusLost);
    this._input.removeEventListener("focusout", this.handleFocusLost);
    this._input.removeEventListener("change", this.handleValidate);

    PixiApp.app.stage.removeChild(this.background);
    if (this.preview) PixiApp.app.stage.removeChild(this.preview);
    this.props.setText("");
  }

  componentDidUpdate() {
    this.updateStyle();
  }

  handleChangeText = (event) => {
    this.props.setText(event.target.value);
    this.preview.text = event.target.value;
    this.preview.pivot.x = this.preview.width / 2;
    this.preview.pivot.y = 0; //this.preview.height/2
    //this.preview.y = PixiApp.bound.top + 15 + this.preview.height /2 + 100

    const [previewX, previewY] = utils.screenToCrop(
      this.props.cropBound,
      window.innerWidth / 2,
      PixiApp.bound.top + 15 + this.preview.height,
    );

    this.preview.x = window.innerWidth / 2;
    //this.preview.y = 100//previewY//PixiApp.bound.top + 15 + this.preview.height /2
    this.preview.scale.set(1);
    const scale = Math.min(TEXT_CENTER_Y / this.preview.height, 1);
    this.preview.scale.set(scale);
    this.preview.y = TEXT_CENTER_Y - this.preview.height / 2;
  };

  handleDeleteText = () => {
    this.props.setText("");
    setTimeout(() => {
      this.handleValidate();
    }, 0);
  };

  handleValidate = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (this.props.text === "") {
      PixiApp.app.stage.removeChild(this.preview);
      //PixiApp.stage.addChild(this.preview)
      this.preview = null;
      this.props.clearPrimaryTool();
      return;
    }

    //this.props.onCreateElement(this.preview)
    const displayId = PixiApp.registerObject(this.preview);
    this.props.addItem(displayId, {
      toolbar: "text",
      styleIndex: this.props.styleIndex,
      colorIndex: this.props.colorIndex,
      allowManipulation: true,
    });
    this.preview.zIndex = 1;

    if (this.initialTransform) {
      this.preview.x = this.initialTransform.x;
      this.preview.y = this.initialTransform.y;
      this.preview.rotation = this.initialTransform.rotation;
      this.preview.scale.x = this.initialTransform.scale;
      this.preview.scale.y = this.initialTransform.scale;
      this.preview.pivot.x = (this.preview.width / this.preview.scale.x) * 0.5;
      this.preview.pivot.y = (this.preview.height / this.preview.scale.y) * 0.5;
      this.preview.resolution =
        PixiApp.cropContainer.scale.x * window.devicePixelRatio; //this.props.cropBound.scale
      this.preview.dirty = true;
    } else {
      this.preview.x = this.props.cropBound.x + this.props.cropBound.width / 2;
      this.preview.y = this.props.cropBound.y + this.props.cropBound.height / 2;
      this.preview.pivot.x = (this.preview.width / this.preview.scale.x) * 0.5;
      this.preview.pivot.y = (this.preview.height / this.preview.scale.y) * 0.5;
      this.preview.scale.set(1 / PixiApp.cropContainer.scale.x);
      this.preview.resolution =
        PixiApp.cropContainer.scale.x * window.devicePixelRatio; //this.props.cropBound.scale
      this.preview.dirty = true;
    }
    PixiApp.stage.addChild(this.preview);
    this.preview = null;
    this.props.clearPrimaryTool();
  };

  handleChangeStyle = () => {
    let index = this.props.styleIndex;
    index++;
    if (index > config.text.length - 1) index = 0;
    this.props.setStyleIndex(index);

    const colorIndexReplace = config.text[index].colorIndexReplace;
    if (
      colorIndexReplace &&
      colorIndexReplace.length === 2 &&
      colorIndexReplace[0] === this.props.colorIndex
    ) {
      this.handleChangeColor(colorIndexReplace[1]);
    }

    setTimeout(() => {
      this.updateStyle();
    }, 0);
  };

  handleChangeAlign = () => {
    let index = this.props.alignIndex;
    index = index + 1 >= config.aligns.length ? 0 : index + 1;
    this.props.setAlignIndex(index);
  };

  /*
  handleChangeStyle = () => {
    let index = this.props.textIndex
    index = index +1 >= config.text.length ? 0 : index+1
    this.props.setStyleIndex(index)
  }
*/
  handleChangeColor = (colorIndex) => {
    this.props.setColorIndex(colorIndex);
    this.handleFocusLost();
  };

  handleSubmit = () => {
    console.log("handle submit");
  };

  render() {
    return (
      <>
        <div className="text-toolbar top-toolbar">
          <div className="left-side">
            <IconButton
              isDisabled
              icon={IconTypes.CLOSE()}
              onClick={this.props.onQuit}
            />
          </div>

          <div className="center">
            <IconButton
              isSelected
              icon={IconTypes.TEXT("selected")}
              onClick={this.handleChangeStyle}
            />
            <IconButton
              icon={config.aligns[this.props.alignIndex].icon}
              onClick={this.handleChangeAlign}
            />
          </div>

          <div className="right-side">
            <IconButton
              isDisabled
              icon={IconTypes.VALIDATE()}
              onClick={this.props.onSave}
            />
          </div>

          <form className="hidden-form" onSubmit={this.handleSubmit}>
            <textarea
              className="hidden-field"
              ref={(c) => (this._input = c)}
              rows={5}
              cols={33}
              autofocus
              multiline
              value={this.props.text}
              onChange={this.handleChangeText}
            />
            <input type="submit" value="Envoyer" />
          </form>
        </div>

        <div className="delete-text">
          <IconButton
            icon={IconTypes.DELETE()}
            onClick={this.handleDeleteText}
          />
        </div>

        <div
          className="bottom-toolbar with-keyboard"
          style={{ bottom: this.props.keyboardHeight || 390 }}
        >
          <ColorSwiper
            onChange={this.handleChangeColor}
            colors={config.colors}
            colorsByPage={7}
            selectedColor={this.props.colorIndex}
          />
        </div>
      </>
    );
  }
}

const mapSelectors = (state) => {
  const editingDisplayId = ToolsSelectors.editingDisplayId(state);
  return {
    text: TextEditorSelectors.text(state),
    alignIndex: TextEditorSelectors.alignIndex(state),
    styleIndex: TextEditorSelectors.styleIndex(state),
    colorIndex: TextEditorSelectors.colorIndex(state),
    fontIndex: TextEditorSelectors.fontIndex(state),
    editingDisplayId: editingDisplayId,
    item: DisplaySelectors.item(state, editingDisplayId),
    cropBound: CropEditorSelectors.bound(state),
    keyboardHeight: AppSelectors.keyboardHeight(state),
  };
};

const mapActions = (dispatch) => ({
  setText: (text) => dispatch(TextEditorActions.setText(text)),
  setAlignIndex: (index) => dispatch(TextEditorActions.setAlignIndex(index)),
  setColorIndex: (index) => dispatch(TextEditorActions.setColorIndex(index)),
  setStyleIndex: (index) => dispatch(TextEditorActions.setStyleIndex(index)),
  //setBackgroundIndex: (index) => dispatch(TextEditorActions.setBackgroundIndex(index)),
  clearPrimaryTool: () => dispatch(ToolsActions.clearPrimaryTool()),
  addItem: (id, meta) => dispatch(DisplayActions.addItem(id, meta)),
});

export default connect(mapSelectors, mapActions)(TextToolbar);
