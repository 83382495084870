import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  saveImageProgress: 0,
  saveImageStatus: "none",
});

export const actions = {
  postMessage: createAction("post message", (type, data) => ({ type, data })),
  saveImage: createAction("save image", (base64Image) => ({ base64Image })),

  setSaveImageProgress: createAction("set save image progress", (percent) => ({
    percent,
  })),
  setSaveImageStatus: createAction("set save image status", (status) => ({
    status,
  })),
};

export const reducer = createReducer(
  {
    [actions.setSaveImageProgress]: (state, { percent }) =>
      state.merge({ saveImageProgress: percent }),
    [actions.setSaveImageStatus]: (state, { status }) =>
      state.merge({ saveImageStatus: status }),
  },
  initialState,
);

export const selectors = {
  saveImageProgress: (state) => state.postMessage.saveImageProgress,
  saveImageStatus: (state) => state.postMessage.saveImageStatus,
};
