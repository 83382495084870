import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  width: 5,
  colorIndex: 0,
  history: [],
});

export const actions = {
  setWidth: createAction("set width", (width) => ({ width })),
  setColorIndex: createAction("set color index", (colorIndex) => ({
    colorIndex,
  })),
  addToHistory: createAction("add to history", (serializedPath) => ({
    serializedPath,
  })),
  historyBack: createAction("history back"),
};

export const reducer = createReducer(
  {
    [actions.setWidth]: (state, { width }) => state.merge({ width }),
    [actions.setColorIndex]: (state, { colorIndex }) =>
      state.merge({ colorIndex }),
    [actions.addToHistory]: (state, { serializedPath }) => {
      const history = state.history.asMutable();
      history.push(serializedPath);
      return state.merge({ history });
    },
    [actions.historyBack]: (state) => {
      if (state.history.length === 0) return state;
      const history = state.history.asMutable();
      history.pop();
      return state.merge({ history });
    },
  },
  initialState,
);

export const selectors = {
  width: (state) => state.drawEditor.width,
  colorIndex: (state) => state.drawEditor.colorIndex,
  lastInHistory: (state) =>
    state.drawEditor.history.length === 0
      ? null
      : state.drawEditor.history[state.drawEditor.history.length - 1],
  history: (state) => state.drawEditor.history,
};
