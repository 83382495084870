import EditorPage from "../pages/EditorPage/EditorPage";
import InfoPage from "../pages/InfoPage/InfoPage";

const routes = {
  EDITOR: "/",
  INFO: "/info",
};

const config = [
  {
    path: routes.EDITOR,
    exact: true,
    component: EditorPage,
  },
  {
    path: routes.INFO,
    component: InfoPage,
  },
];

export default routes;
export { config };
