import { all, put, call, take, takeEvery, delay } from "redux-saga/effects";
import {
  actions as PostMessageActions,
  selectors as PostMessageSelectors,
} from "./redux";
import * as locales from "../../locales";

export default class PostMessageSagas {
  static *postMessage({ payload }) {
    const { type, data } = payload;

    const msg = JSON.stringify({ type, data });
    console.log("post message", msg);
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(msg);
    }
  }

  static *saveImage({ payload }) {
    const { base64Image } = payload;
    yield put(PostMessageActions.postMessage("newUpload", {}));
    yield put(PostMessageActions.setSaveImageProgress(0));
    yield put(PostMessageActions.setSaveImageStatus("sending"));
    let cursor = 0;
    const chunkSize = 55000;
    while (cursor < base64Image.length) {
      const chunk = base64Image.substr(cursor, chunkSize);
      yield put(PostMessageActions.postMessage("upload", chunk));
      yield delay(150);
      const percent = Math.min(cursor / base64Image.length, 1) * 100;
      yield put(PostMessageActions.setSaveImageProgress(percent));
      cursor += chunkSize;
    }
    yield put(PostMessageActions.setSaveImageStatus("done"));
    yield put(PostMessageActions.postMessage("uploadComplete"));
  }

  static *loop() {
    yield all([
      yield takeEvery(
        PostMessageActions.postMessage.getType(),
        PostMessageSagas.postMessage,
      ),
      yield takeEvery(
        PostMessageActions.saveImage.getType(),
        PostMessageSagas.saveImage,
      ),
    ]);
  }
}
