import React from "react";
import { localize } from "redux-i18n";
import * as PIXI from "pixi.js-legacy";
import PixiApp from "../../PixiApp";
import { connect } from "react-redux";
import { actions as AppActions } from "../../redux/app/redux";
import {
  actions as ToolsActions,
  selectors as ToolsSelectors,
} from "../../redux/tools/redux";
import {
  actions as PostMessageActions,
  selectors as PostMessageSelectors,
} from "../../redux/postMessage/redux";
import {
  selectors as CropEditorSelectors,
  actions as CropEditorActions,
} from "../../redux/cropEditor/redux";
import MainToolbar from "../../components/MainToolbar/MainToolbar";
import ContextToolbar from "../../components/ContextToolbar/ContextToolbar";

import * as utils from "./utils";

import defaultImage from "./defaultImage";
//const defaultPicture = 'https://via.placeholder.com/1080x1920';

@localize()
class EditorPage extends React.Component {
  state = {
    imageSet: false,
  };

  last = null;
  allowClick = true;
  params = {};

  componentDidMount() {
    document.addEventListener("message", this.handleMessage, false);
    window.addEventListener("message", this.handleMessage, false);

    if (!window.ReactNativeWebView) {
      this.setBackgroundImage({
        data: JSON.stringify({
          url: defaultImage,
        }),
      });
    }
  }

  componentDidUpdate() {
    console.log("---- did update----");
    this.renderCrop();
  }

  handleMessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      if (data.url !== undefined) {
        this.setBackgroundImage(event);
      } else if (data.keyboardHeight !== undefined) {
        this.setKeyboardHeight(data.keyboardHeight);
      }
    } catch (e) {}
  };

  setKeyboardHeight = (height) => {
    this.props.setKeyboardHeight(height);
    //console.log('get keyboard height : ',height)
  };

  setBackgroundImage = (event) => {
    if (!event.data) return null;
    let data = null;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      return;
    }
    this.params.url = data.url;

    //PIXI.settings.RESOLUTION = window.devicePixelRatio;
    //PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

    const canvas = document.querySelector("#canvas");
    //hammerjs

    PIXI.settings.RESOLUTION = 2; // window.devicePixelRatio *0.25
    this.app = new PIXI.Application({
      width: window.innerWidth,
      height: window.innerHeight,
      view: canvas,
      antialias: true,
      resolution: PIXI.settings.RESOLUTION,
      autoDensity: true,
      forceCanvas: false,
    });
    //this.app.renderer = new PIXI.CanvasRenderer({width:window.innerWidth, height:window.innerHeight, view:canvas,antialias:true, resolution:PIXI.settings.RESOLUTION,autoDensity: true, forceCanvas:true})
    this.app.renderer.backgroundColor = 0x000000;

    //this.app.renderer.view.style.position = "absolute";
    this.app.renderer.view.style.display = "block";
    this.app.renderer.autoResize = true;
    this.app.renderer.resize(window.innerWidth, window.innerHeight);
    PixiApp.init(this.app);

    const loader = new PIXI.Loader();

    if (!data.headers) {
      data.headers = {};
    }
    //loader.add('background','assets/images/background.jpg')

    const img = new Image();

    img.onload = () => {
      console.log("image loaded");
      const ratioW = 2048 / img.width;
      const ratioH = 2048 / img.height;
      const ratio = Math.min(Math.min(ratioW, ratioH), 1);
      const finalWidth = img.width * ratio;
      const finalHeight = img.height * ratio;

      const canvas = document.createElement("canvas");
      canvas.width = finalWidth;
      canvas.height = finalHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, finalWidth, finalHeight);

      const resources = {
        background: {
          texture: PIXI.Texture.from(canvas, { resolution: 1 }),
        },
      };

      //------
      const background = new PIXI.Sprite(resources.background.texture);

      PixiApp.backgroundContainer.addChild(background);

      this.bound = {
        top: background.y,
        right: background.x + background.width,
        bottom: background.y + background.height,
        left: background.x,
        width: background.width,
        height: background.height,
      };

      PixiApp.bound = { ...this.bound };

      this.props.setInitialBound(
        0,
        0,
        resources.background.texture.width,
        resources.background.texture.height,
      );

      this.renderCrop();
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(`{ "type": "ready"}`);
      }
      this.setState({ imageSet: true });
    };
    img.src = this.params.url || defaultImage;

    /*
    loader.add('background', this.params.url || defaultImage, {crossOrigin: 'anonymous', ...data.headers })
    loader.load((loader, resources) => {
      console.log('background loaded',resources.background.texture.width,resources.background.texture.height)
      const background = new PIXI.Sprite(resources.background.texture)

      PixiApp.backgroundContainer.addChild(background)


      this.bound = {
        top:background.y,
        right:background.x+background.width,
        bottom: background.y+background.height,
        left: background.x,
        width:background.width,
        height:background.height
      }

      PixiApp.bound = {...this.bound}

     this.props.setInitialBound(0,0,resources.background.texture.width,resources.background.texture.height)


     this.renderCrop()
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(`{ "type": "ready"}`)
      }
      this.setState({ imageSet: true });
    });

    */
  };

  renderCrop() {
    if (!this.cropGraphics) {
      this.cropGraphics = new PIXI.Graphics();
      PixiApp.maskContainer.addChild(this.cropGraphics);
    }
    this.cropGraphics.clear();
    PixiApp.backgroundContainer.scale.set(1);

    const ww = window.innerWidth;
    const wh = window.innerHeight;

    let cropBound;
    let viewport;

    if (this.props.isInCropEditor) {
      viewport = PixiApp.CROP_VIEWPORT;
      cropBound = {
        x: 0,
        y: 0,
        width: this.props.initialBound.width,
        height: this.props.initialBound.height,
      };
    } else {
      viewport = PixiApp.FULL_VIEWPORT;
      cropBound = this.props.cropBound;
    }

    const ratio = Math.min(
      viewport.width / cropBound.width,
      viewport.height / cropBound.height,
    );
    const finalWidth = this.props.initialBound.width * ratio;
    const finalHeight = this.props.initialBound.height * ratio;
    const dx = cropBound.x * -ratio;
    const dy = cropBound.y * -ratio;

    //PixiApp.cropContainer.width = finalWidth
    //PixiApp.cropContainer.height = finalHeight
    PixiApp.cropContainer.scale.set(ratio);
    PixiApp.cropContainer.x =
      viewport.width / 2 - (cropBound.width * ratio) / 2 + dx + viewport.x;
    PixiApp.cropContainer.y =
      viewport.height / 2 - (cropBound.height * ratio) / 2 + dy + viewport.y;

    const top =
      viewport.height / 2 - (cropBound.height * ratio) / 2 + viewport.y;
    const bottom =
      viewport.height / 2 + (cropBound.height * ratio) / 2 + viewport.y;
    const left =
      viewport.width / 2 - (cropBound.width * ratio) / 2 + viewport.x;
    const right =
      viewport.width / 2 + (cropBound.width * ratio) / 2 + viewport.x;

    this.cropGraphics.beginFill(0);
    //haut
    this.cropGraphics.drawRect(-1, -1, ww + 1, top + 1);

    //bas
    this.cropGraphics.drawRect(0, bottom, ww, wh - bottom);

    //gauche
    this.cropGraphics.drawRect(-1, -1, left + 1, wh + 1);

    //droite
    this.cropGraphics.drawRect(right, 0, ww - right, wh);
  }

  XXrenderCrop() {
    if (!this.cropGraphics) {
      this.cropGraphics = new PIXI.Graphics();
      PixiApp.cropContainer.addChild(this.cropGraphics);
      //this.app.stage.addChild(this.cropContainer)
    }
    this.cropGraphics.clear();

    if (this.props.isInCropEditor) {
      const cropViewScale = (window.innerHeight - 90) / window.innerHeight;
      PixiApp.cropContainer.x =
        window.innerWidth / 2 - (window.innerWidth * cropViewScale) / 2;
      PixiApp.cropContainer.y = 70;
      PixiApp.cropContainer.scale.x = cropViewScale;
      PixiApp.cropContainer.scale.y = cropViewScale;
      return;
    }

    this.cropGraphics.beginFill(0);

    const ww = window.innerWidth;
    const wh = window.innerHeight;

    const screenBound = utils.calculateScreenBound(this.props.cropBound);

    console.log("### window width", ww);

    console.log("screen bound", screenBound);
    //haut
    this.cropGraphics.beginFill(0xff0000);
    this.cropGraphics.drawRect(
      0,
      0,
      PixiApp.backgroundContainer.width,
      screenBound.y,
    );

    //gauche

    this.cropGraphics.beginFill(0xff00ff);
    this.cropGraphics.drawRect(0, 0, 100, 200);

    //droite
    this.cropGraphics.beginFill(0xff0000);
    //console.log('border right', screenBound.x+screenBound.width, Math.maxww - (screenBound.x+screenBound.width))
    this.cropGraphics.drawRect(
      screenBound.x + screenBound.width,
      0,
      Math.max(ww - (screenBound.x + screenBound.width), 0),
      wh,
    );

    this.cropGraphics.beginFill(0x00ff00);
    //bottom
    //this.cropGraphics.drawRect(0,screenBound.y+screenBound.height,ww,Math.max(wh -(screenBound.y+screenBound.height),0))
    this.cropGraphics.drawRect(
      0,
      screenBound.y + screenBound.height,
      PixiApp.backgroundContainer.width,
      wh * this.props.cropBound.scale -
        (screenBound.y + screenBound.height) +
        90,
      //Math.max(wh -(screenBound.y+screenBound.height),0)
    );
    console.log("----- draw crop------");
    //this.app.stage.x = -screenBound.x
    PixiApp.cropContainer.scale.x = screenBound.scale;
    PixiApp.cropContainer.scale.y = screenBound.scale;
    PixiApp.cropContainer.x =
      -screenBound.x * screenBound.scale +
      ww / 2 -
      (screenBound.width * screenBound.scale) / 2;
    PixiApp.cropContainer.y =
      -screenBound.y * screenBound.scale +
      wh / 2 -
      (screenBound.height * screenBound.scale) / 2;
    //this.cropGraphics.clear()
  }

  handleSave = () => {
    PixiApp.zoomContainer.scale.set(1);
    PixiApp.zoomContainer.x = 0;
    PixiApp.zoomContainer.y = 0;
    /*
    PixiApp.cropContainer.scale.set(1)
    PixiApp.cropContainer.x = 0
    PixiApp.cropContainer.y = 0
*/
    PixiApp.app.renderer.on("postrender", () => {
      PixiApp.app.renderer.off("postrender");
      this.canvas = document.createElement("canvas");
      this.canvas.width = this.props.cropBound.width * PIXI.settings.RESOLUTION;
      this.canvas.height =
        this.props.cropBound.height * PIXI.settings.RESOLUTION;
      const ctx = this.canvas.getContext("2d");
      ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      const canvasSource = document.querySelector("#canvas");
      //ctx.drawImage(canvasSource,this.bound.left*PIXI.settings.RESOLUTION,this.bound.top*PIXI.settings.RESOLUTION,this.bound.width*PIXI.settings.RESOLUTION,this.bound.height*PIXI.settings.RESOLUTION, 0,0, this.imageWidth, this.imageHeight)

      const viewport = PixiApp.FULL_VIEWPORT;
      const ratio = Math.min(
        viewport.width / this.props.cropBound.width,
        viewport.height / this.props.cropBound.height,
      );

      const top =
        viewport.height / 2 -
        (this.props.cropBound.height * ratio) / 2 +
        viewport.y;
      const bottom =
        viewport.height / 2 +
        (this.props.cropBound.height * ratio) / 2 +
        viewport.y;
      const left =
        viewport.width / 2 -
        (this.props.cropBound.width * ratio) / 2 +
        viewport.x;
      const right =
        viewport.width / 2 +
        (this.props.cropBound.width * ratio) / 2 +
        viewport.x;

      const force = (v) => {
        return Math.floor(Math.max(0, v));
      };

      ctx.drawImage(
        canvasSource,
        force(left * PIXI.settings.RESOLUTION),
        force(top * PIXI.settings.RESOLUTION),
        force(this.props.cropBound.width * ratio * PIXI.settings.RESOLUTION),
        force(this.props.cropBound.height * ratio * PIXI.settings.RESOLUTION),

        0,
        0,
        force(this.props.cropBound.width * PIXI.settings.RESOLUTION),
        force(this.props.cropBound.height * PIXI.settings.RESOLUTION),
      );

      this.canvas.style.position = "fixed";
      this.canvas.style.top = 0;
      this.canvas.style.left = 0;
      this.download(this.canvas, "download.png");
    });
  };

  download(canvas, filename) {
    var lnk = document.createElement("a");
    lnk.download = filename;
    let data = canvas.toDataURL("image/jpeg", 0.7);
    lnk.href = data;

    //lnk.href = canvas.toDataURL('image/png');
    // window.ReactNativeWebView.postMessage(`{ "url" : "${lnk.href}" }`)
    //lnk.click()
    this.props.saveImage(lnk.href);
  }

  handleQuit = () => {
    window.ReactNativeWebView.postMessage(`{ "type": "quit"}`);
  };

  render() {
    const { t } = this.props;

    if (!this.params.url || !this.state.imageSet) {
      return <div className="emptyPage" />;
    }
    return (
      <>
        {this.props.saveImageStatus === "sending" && (
          <div className="progress">
            <div className="progress-container">
              <div
                className="progress-content"
                style={{ width: this.props.saveImageProgress + "%" }}
              ></div>
            </div>
          </div>
        )}
        {this.props.isInCropEditor && <div></div>}
        <ContextToolbar onSave={this.handleSave} onQuit={this.handleQuit} />
      </>
    );
  }
}

const mapSelectors = (state) => ({
  saveImageProgress: PostMessageSelectors.saveImageProgress(state),
  saveImageStatus: PostMessageSelectors.saveImageStatus(state),
  cropBound: CropEditorSelectors.bound(state),
  initialBound: CropEditorSelectors.initialBound(state),
  isInCropEditor: CropEditorSelectors.isInEditMode(state),
});

const mapActions = (dispatch) => ({
  useText: (text) => dispatch(ToolsActions.useText(text)),
  useDraw: () => dispatch(ToolsActions.useDraw()),
  saveImage: (base64Image) =>
    dispatch(PostMessageActions.saveImage(base64Image)),
  setInitialBound: (x, y, width, height, scale) =>
    dispatch(CropEditorActions.setInitialBound(x, y, width, height, scale)),
  setBound: (x, y, width, height, scale) =>
    dispatch(CropEditorActions.setBound(x, y, width, height, scale)),
  setKeyboardHeight: (height) => dispatch(AppActions.setKeyboardHeight(height)),
});

export default connect(mapSelectors, mapActions)(EditorPage);
