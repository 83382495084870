const iconType = (config) => {
  return (configName = "default") => {
    return config[configName];
  };
};

export default {
  CLOSE: iconType({
    default: require("./svg/close_white.svg"),
  }),

  VALIDATE: iconType({
    default: require("./svg/validate.svg"),
  }),

  DRAW_BASIC: iconType({
    default: require("./svg/play_white.svg"),
    selected: require("./svg/play.svg"),
  }),
  DRAW_GLOW: iconType({
    default: require("./svg/glow_white.svg"),
    selected: require("./svg/glow.svg"),
  }),

  TEXT: iconType({
    default: require("./svg/text_white.svg"),
    selected: require("./svg/text.svg"),
  }),
  TEXT_ALIGN: iconType({
    left: require("./svg/left_white.svg"),
    center: require("./svg/center_white.svg"),
    right: require("./svg/right_white.svg"),
  }),
  DELETE: iconType({
    default: require("./svg/delete.svg"),
  }),
  CROP: iconType({
    default: require("./svg/crop_white.svg"),
    selected: require("./svg/crop.svg"),
  }),
  ROTATE: iconType({
    default: require("./svg/rotate.svg"),
  }),
  UNDO: iconType({
    default: require("./svg/undo.svg"),
  }),

  /*
  TEXT_ALIGN_CENTER:"./../../assets/svg/align-center.svg",
  TEXT_ALIGN_LEFT:"./../../assets/svg/align-left.svg",
  TEXT_ALIGN_RIGHT:"./../../assets/svg/align-right.svg",
  HASH_TAG:"./../../assets/svg/hashtag.svg",
  MARKER:"./../../assets/svg/marker.svg",
  TEXT_BACKGROUND_BLACK:"./../../assets/svg/text-black-background.svg",
  TEXT_BACKGROUND_LIGHT:"./../../assets/svg/text-light-background.svg",
  TEXT_BACKGROUND_NO:"./../../assets/svg/text-no-background.svg",
  TEXT_WHITE_BACKGROUND:"./../../assets/svg/text-white-background.svg",
  TEXT:"./../../assets/svg/text.svg",
  CANCEL:"./../../assets/svg/cancel.svg",
  UNDO:"./../../assets/svg/undo.svg",
  VALIDATE:"./../../assets/svg/validate.svg",
*/
};
