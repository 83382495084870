import * as app from "./app/redux";
import * as translation from "./translation/redux";
import * as tools from "./tools/redux";
import * as display from "./display/redux";
import * as textEditor from "./textEditor/redux";
import * as tagEditor from "./tagEditor/redux";
import * as drawEditor from "./drawEditor/redux";
import * as postMessage from "./postMessage/redux";
import * as cropEditor from "./cropEditor/redux";

export default {
  app,
  translation,
  tools,
  display,
  textEditor,
  tagEditor,
  drawEditor,
  postMessage,
  cropEditor,
};
