import React from "react";
import Hammer from "hammerjs";
import * as PIXI from "pixi.js-legacy";
import { connect } from "react-redux";
import PixiApp from "../../../../PixiApp";
import { actions as ToolsActions } from "../../../../redux/tools/redux";
import {
  actions as DisplayActions,
  selectors as DisplaySelectors,
} from "../../../../redux/display/redux";
import Icon from "../../../Icon/Icon";
import IconTypes from "../../../Icon/Types";
import IconButton from "../../../../components/IconButton/IconButton";
import gsap from "gsap";
import classnames from "classnames";
import { selectors as CropEditorSelectors } from "../../../../redux/cropEditor/redux";
import * as utils from "../../../../pages/EditorPage/utils";

var SinglePan = new Hammer.Pan({
  event: "singlepan",
  pointers: 1,
  threshold: 0,
});

var DoublePan = new Hammer.Pan({
  event: "doublepan",
  pointers: 2,
  threshold: 0,
});

class ManipulationToolbar extends React.PureComponent {
  state = { isInDrop: false };

  currentSelection = null;
  offset = { x: 0, y: 0 };

  recalculatePan = true;

  componentDidMount() {
    const canvas = document.querySelector("#canvas");
    this.mc = new Hammer.Manager(canvas);
    // this.mc.add(SinglePan);
    // this.mc.add(DoublePan)
    this.mc.add(new Hammer.Pan({ threshold: 0 }));
    this.mc
      .add(new Hammer.Rotate({ threshold: 0 }))
      .recognizeWith(this.mc.get("pan"));
    this.mc
      .add(new Hammer.Pinch({ threshold: 0 }))
      .recognizeWith([this.mc.get("pan"), this.mc.get("rotate")]);
    this.mc.add(new Hammer.Tap());

    this.offset.x = -canvas.getBoundingClientRect().left;
    this.offset.y = -canvas.getBoundingClientRect().top;

    this.mc.on("rotatestart panstart pinchstart", (evt) => {
      const p = new PIXI.Point(
        evt.center.x + this.offset.x,
        evt.center.y + this.offset.y,
      );
      const hit = PixiApp.app.renderer.plugins.interaction.hitTest(p);
      if (hit) {
        if (this.currentSelection) {
          this.currentSelection.zIndex = 0;
        }
        this.currentSelection = hit;
        this.currentSelectionId = PixiApp.getId(hit);
        this.currentSelection.zIndex = 1;
      } else {
        this.currentSelection = null;
        this.currentSelectionId = null; // PixiApp.getId(hit)
      }
    });

    this.mc.on("panstart", (evt) => {
      console.log("pan start ==> ", evt);
    });

    this.mc.on("rotatestart rotatemove", this.handleRotate);
    // this.mc.on("singlepanstart singlepanmove singlepanend singlepancancel", this.handleSinglePan);
    this.mc.on("panstart panmove", this.handlePan);
    this.mc.on("pinchstart pinchmove", this.handlePinch);
    this.mc.on("tap", this.handleTab);
  }

  handleDownload = () => {
    PixiApp.app.renderer.on("postrender", () => {
      PixiApp.app.renderer.off("postrender");
      this.canvas = document.createElement("canvas");
      this.canvas.width = 1080 / 4;
      this.canvas.height = 1920 / 4;
      const ctx = this.canvas.getContext("2d");
      ctx.drawImage(
        document.querySelector("#canvas"),
        -30,
        0,
        this.canvas.width,
        this.canvas.height,
      );
      this.canvas.style.position = "fixed";
      this.canvas.style.top = 0;
      this.canvas.style.left = 0;
      this.download(this.canvas, "download.png");
    });
  };

  download(canvas, filename) {
    var lnk = document.createElement("a"),
      e;
    lnk.download = filename;
    lnk.href = canvas.toDataURL("image/png;base64");
    if (document.createEvent) {
      e = document.createEvent("MouseEvents");
      e.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null,
      );

      lnk.dispatchEvent(e);
    } else if (lnk.fireEvent) {
      lnk.fireEvent("onclick");
    }
  }

  componentWillUnmount() {
    this.mc.destroy();
  }

  handleTab = (ev) => {
    const p = new PIXI.Point(
      ev.center.x + this.offset.x,
      ev.center.y + this.offset.y,
    );
    const hit = PixiApp.app.renderer.plugins.interaction.hitTest(p);
    if (hit) {
      const id = PixiApp.getId(hit);
      const item = this.props.items[id];
      this.props.edit(item.toolbar, id);
    }
  };

  handleRotate = (ev) => {
    if (!this.currentSelection) return;
    if (ev.type === "rotatestart") {
      this.initAngle = ev.rotation;
      this.startSpriteRot = this.currentSelection.rotation;
    }
    this.currentSelection.rotation =
      this.startSpriteRot + ((ev.rotation - this.initAngle) / 180) * Math.PI;
  };

  handlePan = (ev) => {
    if (!this.currentSelection) {
      this.handlePanView(ev);
      return;
    }

    const [cropPx, cropPy] = utils.screenToCrop(
      this.props.cropBound,
      ev.center.x,
      ev.center.y,
    );

    if (ev.type === "panstart") {
      this.startScale = this.currentSelection.scale.x;
      this.offsetX = this.currentSelection.x; //- cropPx
      this.offsetY = this.currentSelection.y; //- cropPy
      return;
    }

    // this.currentSelection.x = cropPx+this.offsetX
    // this.currentSelection.y = cropPy+this.offsetY

    this.currentSelection.x =
      this.offsetX +
      ev.deltaX / PixiApp.zoomContainer.scale.x / PixiApp.cropContainer.scale.x;
    this.currentSelection.y =
      this.offsetY +
      ev.deltaY / PixiApp.zoomContainer.scale.y / PixiApp.cropContainer.scale.y;
  };

  handlePanView = (ev) => {
    if (ev.type === "panstart") {
      this.startX = PixiApp.zoomContainer.x;
      this.startY = PixiApp.zoomContainer.y;
      return;
    }

    const px = this.startX + ev.deltaX;
    const py = this.startY + ev.deltaY;
    const ww = window.innerWidth;
    const wh = window.innerHeight;
    const contentWidth = ww * PixiApp.zoomContainer.scale.x;
    const contentHeight = wh * PixiApp.zoomContainer.scale.x;

    PixiApp.zoomContainer.x = Math.min(Math.max(px, ww - contentWidth), 0);
    PixiApp.zoomContainer.y = Math.min(Math.max(py, wh - contentHeight), 0);
  };

  handlePinch = (ev) => {
    if (!this.currentSelection) {
      this.handlePinchView(ev);
      return;
    }
    if (ev.type === "pinchstart") {
      this.startScale = this.currentSelection.scale.x;
    }
    this.currentSelection.scale.x = this.currentSelection.scale.y =
      this.startScale * ev.scale;
    this.currentSelection.pivot.x =
      (this.currentSelection.width / this.currentSelection.scale.x) * 0.5;
    this.currentSelection.pivot.y =
      (this.currentSelection.height / this.currentSelection.scale.y) * 0.5;
    // if(ev.type === "pinchend") {
    this.currentSelection.resolution =
      this.currentSelection.scale.x * window.devicePixelRatio;
    this.currentSelection.dirty = true;
    // }
  };

  handlePinchView = (ev) => {
    if (ev.type === "pinchstart") {
      this.startScale = PixiApp.zoomContainer.scale.x;

      this.startX = PixiApp.zoomContainer.x;
      this.startY = PixiApp.zoomContainer.y;

      this.deltaX = ev.center.x - this.startX;
      this.deltaY = ev.center.y - this.startY;
    }

    const scale = this.startScale * ev.scale;
    const scaleDelta = ev.scale - 1;
    PixiApp.zoomContainer.scale.set(Math.max(Math.min(3, scale), 1));

    if (scale >= 3) return;

    const px = this.startX - this.deltaX * scaleDelta;
    const py = this.startY - this.deltaY * scaleDelta;

    //PixiApp.zoomContainer.x = Math.min(px,0)
    //PixiApp.zoomContainer.y = Math.min(py,0)

    const ww = window.innerWidth;
    const wh = window.innerHeight;
    const contentWidth = ww * PixiApp.zoomContainer.scale.x;
    const contentHeight = wh * PixiApp.zoomContainer.scale.x;

    PixiApp.zoomContainer.x = Math.min(Math.max(px, ww - contentWidth), 0);
    PixiApp.zoomContainer.y = Math.min(Math.max(py, wh - contentHeight), 0);
  };

  handleFocusSprite = (e, id) => {
    if (this.currentSelection) this.currentSelection.zIndex = 0;
    this.currentSelection = e.target;
    this.currentSelectionId = id;
    this.currentSelection.zIndex = 1;
  };

  handleClickSprite = (id, target) => {
    const item = this.props.items[id];
    this.props.edit(item.toolbar, id);
  };

  render() {
    return (
      <>
        <div className="top-toolbar">
          <div className="right-side">
            <IconButton icon={IconTypes.CLOSE()} onClick={this.props.onQuit} />
          </div>

          <div className="center">
            <IconButton
              icon={IconTypes.DRAW_BASIC()}
              onClick={this.props.useDraw}
            />
            <IconButton icon={IconTypes.TEXT()} onClick={this.props.useText} />
            <IconButton icon={IconTypes.CROP()} onClick={this.props.useCrop} />
          </div>

          <div className="left-side">
            <IconButton
              icon={IconTypes.VALIDATE()}
              onClick={this.props.onSave}
            />
          </div>
        </div>

        <div
          className={classnames({
            "icon-delete": true,
            opened: this.state.isInDrop,
          })}
        >
          <Icon icon={IconTypes.DELETE()} />
        </div>
      </>
    );
  }
}

const mapSelectors = (state) => ({
  items: DisplaySelectors.items(state),
  cropBound: CropEditorSelectors.bound(state),
});

const mapActions = (dispatch) => ({
  edit: (toolbar, editingDisplayId) =>
    dispatch(ToolsActions.edit(toolbar, editingDisplayId)),
  useText: () => dispatch(ToolsActions.useText()),
  useDraw: () => dispatch(ToolsActions.useDraw()),
  useTag: () => dispatch(ToolsActions.useTag()),
  useCrop: () => dispatch(ToolsActions.useCrop()),
  updateMeta: (id, meta) => dispatch(DisplayActions.updateMeta(id, meta)),
});

export default connect(mapSelectors, mapActions)(ManipulationToolbar);
