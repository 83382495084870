import React from "react";
import { BrowserRouter, Switch, Route, HashRouter } from "react-router-dom";
import { config } from "./index";

const Router = () => (
  <HashRouter>
    <Switch>
      {config.map((e) => (
        <Route
          key={e.path}
          exact={e.exact === true}
          path={e.path}
          component={e.component}
        />
      ))}
    </Switch>
  </HashRouter>
);

export default Router;
