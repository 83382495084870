import React from "react";
import PixiApp from "../../PixiApp";
import { connect } from "react-redux";
import {
  actions as ToolsActions,
  selectors as ToolsSelectors,
} from "../../redux/tools/redux";
import Icon from "../Icon/Icon";
import IconTypes from "../Icon/Types";

class MainToolbar extends React.PureComponent {
  saveBase64 = null;

  handleDownload = () => {
    PixiApp.app.renderer.on("postrender", () => {
      PixiApp.app.renderer.off("postrender");
      this.canvas = document.createElement("canvas");
      this.canvas.width = 1080 / 4;
      this.canvas.height = 1920 / 4;
      const ctx = this.canvas.getContext("2d");
      ctx.drawImage(
        document.querySelector("#canvas"),
        -30,
        0,
        this.canvas.width,
        this.canvas.height,
      );
      this.canvas.style.position = "fixed";
      this.canvas.style.top = 0;
      this.canvas.style.left = 0;
      this.download(this.canvas, "download.png");
    });
  };

  download(canvas, filename) {
    var lnk = document.createElement("a"),
      e;
    lnk.download = filename;
    lnk.href = canvas.toDataURL("image/png;base64");
    if (document.createEvent) {
      e = document.createEvent("MouseEvents");
      e.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null,
      );

      lnk.dispatchEvent(e);
    } else if (lnk.fireEvent) {
      lnk.fireEvent("onclick");
    }
  }

  render() {
    return (
      <div className="main-toolbar">
        <div className="button">quit</div>
        <div className="button" onClick={() => this.props.useText()}>
          <Icon icon={IconTypes.TEXT} />
        </div>
        <div className="button" onClick={() => this.props.useDraw()}>
          <Icon icon={IconTypes.MARKER} />
        </div>
        <div className="button">
          <Icon icon={IconTypes.HASH_TAG} />
        </div>
        <div className="button" onClick={this.handleDownload}>
          save
        </div>
      </div>
    );
  }
}

const mapSelectors = (state) => ({});

const mapActions = (dispatch) => ({
  useText: () => dispatch(ToolsActions.useText()),
  useDraw: () => dispatch(ToolsActions.useDraw()),
});

export default connect(mapSelectors, mapActions)(MainToolbar);
