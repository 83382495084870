import React from "react";
import Icon from "../Icon/Icon";
import classnames from "classnames";

export default class IconButton extends React.PureComponent {
  render() {
    const cName = classnames({
      button: true,
      "icon-button": true,
      selected: this.props.isSelected,
      disabled: this.props.isDisabled,
    });
    return (
      <div className={cName} onClick={this.props.onClick}>
        <Icon icon={this.props.icon} />
      </div>
    );
  }
}
