import React from "react";
import Hammer from "hammerjs";
import * as PIXI from "pixi.js-legacy";
import PixiApp from "../../../../PixiApp";
import { connect } from "react-redux";
import IconButton from "../../../IconButton/IconButton";
import IconTypes from "../../../Icon/Types";
import {
  actions as ToolsActions,
  selectors as ToolsSelectors,
} from "../../../../redux/tools/redux";
import {
  actions as CropEditorActions,
  selectors as CropEditorSelectors,
} from "../../../../redux/cropEditor/redux";

const MIN_CROP = 40;

class CropToolbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.rotationIndex = props.rotationIndex;
    const viewport = PixiApp.CROP_VIEWPORT;

    const initialBound = props.initialBound;
    const cropBound = props.cropBound;
    const ratio = Math.min(
      viewport.width / initialBound.width,
      viewport.height / initialBound.height,
    );
    const ratio2 = Math.min(
      PixiApp.FULL_VIEWPORT.width / initialBound.width,
      PixiApp.FULL_VIEWPORT.height / initialBound.height,
    );
    const top =
      viewport.height / 2 - (initialBound.height * ratio) / 2 + viewport.y;
    const bottom =
      viewport.height / 2 + (initialBound.height * ratio) / 2 + viewport.y;
    const left =
      viewport.width / 2 - (initialBound.width * ratio) / 2 + viewport.x;
    const right =
      viewport.width / 2 + (initialBound.width * ratio) / 2 + viewport.x;

    this.crop = {
      x: left + cropBound.x * ratio,
      y: top + cropBound.y * ratio,
      width: cropBound.width * ratio,
      height: cropBound.height * ratio,
    };
  }

  componentWillUnmount() {
    this.props.setEditMode(false);
    this.ctl.destroy();
    this.st.destroy();
    this.ctr.destroy();
    this.sr.destroy();
    this.cbr.destroy();
    this.sb.destroy();
    this.cbl.destroy();
    this.sl.destroy();
  }

  componentDidMount() {
    this.props.setEditMode(true);

    this.ctl = this.createHammer(".corner.top.left", (evt) => {
      this.resizeTop(evt);
      this.resizeLeft(evt);
      this.refreshCrop();
    });

    this.st = this.createHammer(".side.top", (evt) => {
      this.resizeTop(evt);
      this.refreshCrop();
    });

    this.ctr = this.createHammer(".corner.top.right", (evt) => {
      this.updateWidth(evt);
      this.resizeTop(evt);
      this.refreshCrop();
    });

    this.sr = this.createHammer(".side.right", (evt) => {
      this.updateWidth(evt);
      this.refreshCrop();
    });

    this.cbr = this.createHammer(".corner.bottom.right", (evt) => {
      this.updateWidth(evt);
      this.updateHeight(evt);
      this.refreshCrop();
    });

    this.sb = this.createHammer(".side.bottom", (evt) => {
      this.updateHeight(evt);
      this.refreshCrop();
    });

    this.cbl = this.createHammer(".corner.bottom.left", (evt) => {
      this.updateHeight(evt);
      this.resizeLeft(evt);
      this.refreshCrop();
    });

    this.sl = this.createHammer(".side.left", (evt) => {
      this.resizeLeft(evt);
      this.refreshCrop();
    });

    PixiApp.zoomContainer.scale.set(1);
    PixiApp.zoomContainer.x = PixiApp.zoomContainer.y = 1;

    this.cropContainer = document.querySelector(".crop-container");
    this.refreshCrop();
  }

  createHammer = (selector, action) => {
    const hammer = new Hammer(document.querySelector(selector), {
      threshold: 0,
      inputClass: Hammer.TouchMouseInput,
      domEvents: true,
    });
    hammer.on("panmove", action);
    return hammer;
  };

  refreshCrop = () => {
    this.cropContainer.style.left = this.crop.x + "px";
    this.cropContainer.style.top = this.crop.y + "px";
    this.cropContainer.style.width = this.crop.width + "px";
    this.cropContainer.style.height = this.crop.height + "px";
  };

  updateWidth = (evt) => {
    const maxBound = this.getMaxBound();
    let width = Math.max(evt.center.x - this.crop.x, MIN_CROP);
    width = Math.min(maxBound.x + maxBound.width - this.crop.x, width);
    this.crop.width = width;
  };

  updateHeight = (evt) => {
    const maxBound = this.getMaxBound();
    let height = Math.max(evt.center.y - this.crop.y, MIN_CROP);
    height = Math.min(maxBound.y + maxBound.height - this.crop.y, height);
    this.crop.height = height;
  };

  resizeTop = (evt) => {
    const maxBound = this.getMaxBound();
    let py = Math.min(evt.center.y, this.crop.y + this.crop.height - MIN_CROP);
    py = Math.max(maxBound.y, py);
    const oldCrop = { ...this.crop };
    this.crop.y = py;
    const dy = oldCrop.y - py;
    this.crop.height = oldCrop.height + dy;
  };

  resizeLeft = (evt) => {
    const maxBound = this.getMaxBound();
    let px = Math.min(evt.center.x, this.crop.x + this.crop.width - MIN_CROP);
    px = Math.max(maxBound.x, px);

    const oldCrop = { ...this.crop };
    this.crop.x = px;
    const dx = oldCrop.x - px;
    this.crop.width = oldCrop.width + dx;
  };

  getMaxBound() {
    const viewport = PixiApp.CROP_VIEWPORT;

    const cropBound = this.props.initialBound;
    const ratio = Math.min(
      viewport.width / cropBound.width,
      viewport.height / cropBound.height,
    );
    const top =
      viewport.height / 2 - (cropBound.height * ratio) / 2 + viewport.y;
    const bottom =
      viewport.height / 2 + (cropBound.height * ratio) / 2 + viewport.y;
    const left =
      viewport.width / 2 - (cropBound.width * ratio) / 2 + viewport.x;
    const right =
      viewport.width / 2 + (cropBound.width * ratio) / 2 + viewport.x;

    const o = {
      x: left,
      y: top,
      width: right - left,
      height: bottom - top,
    };

    return o;
  }

  applyCrop = () => {
    const viewport = PixiApp.CROP_VIEWPORT;
    const maxBound = this.getMaxBound();
    const ratio = Math.min(
      viewport.width / this.props.initialBound.width,
      viewport.height / this.props.initialBound.height,
    );

    this.props.setBound(
      (this.crop.x - maxBound.x) / ratio,
      (this.crop.y - maxBound.y) / ratio,
      this.crop.width / ratio,
      this.crop.height / ratio,
      1,
    );
  };

  handleValidateCrop = () => {
    this.applyCrop();
    this.props.clearPrimaryTool();
  };

  useText = () => {
    this.applyCrop();
    this.props.useText();
  };

  useDraw = () => {
    this.applyCrop();
    this.props.useDraw();
  };

  rotateObject = (object, rot, dx) => {
    const parent = object.parent;
    const tempContainer = new PIXI.Container();
    parent.addChild(tempContainer);
    tempContainer.x = rot.x + dx;
    tempContainer.y = rot.y;
    tempContainer.addChild(object);
    //object.x -= centerX
    //object.y -= centerY
    tempContainer.rotation = Math.PI / 2;
    const globalPos = object.getGlobalPosition();
    const pos = parent.toLocal(globalPos);
    parent.addChild(object);
    object.x = pos.x;
    object.y = pos.y;
    object.rotation += Math.PI / 2;
    parent.removeChild(tempContainer);
  };

  handleRotate = () => {
    const w = this.props.initialBound.width;
    const h = this.props.initialBound.height;
    const rots = [
      { r: 0, x: 0, y: 0 },
      { r: 1, x: h, y: 0 },
      { r: 2, x: h, y: 0 },
      { r: 3, x: h, y: 0 },
    ];

    const ids = PixiApp.getIds();
    this.rotationIndex++;
    let dx = 0;
    if (this.rotationIndex > 3) {
      this.rotationIndex = 0;
      dx = h;
    }

    const p = new PIXI.Point(0, 0);
    ids.forEach((id) => {
      const object = PixiApp.getObject(id);
      this.rotateObject(object, rots[this.rotationIndex], dx);
    });

    this.rotateObject(
      PixiApp.backgroundContainer.children[0],
      rots[this.rotationIndex],
      dx,
    );

    const viewport = PixiApp.CROP_VIEWPORT;
    //const ratio = Math.min( viewport.width/cropBound.width,  viewport.height / cropBound.height)

    //comme on va tourner l'image, le ratio changera alors, dans nextRatio on inverse width et height
    const currentRatio = Math.min(
      viewport.width / this.props.initialBound.width,
      viewport.height / this.props.initialBound.height,
    );
    const nextRatio = Math.min(
      viewport.width / this.props.initialBound.height,
      viewport.height / this.props.initialBound.width,
    );

    const centerX = viewport.width / 2 + viewport.x;
    const centerY = viewport.height / 2 + viewport.y;
    const deltaX = this.crop.x - centerX;
    const deltaY = this.crop.y - centerY;
    const delta = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    let rad = Math.atan2(deltaY, deltaX);
    rad += Math.PI / 2;
    this.crop.x =
      ((Math.cos(rad) * delta) / currentRatio) * nextRatio +
      centerX -
      (this.crop.height / currentRatio) * nextRatio;
    this.crop.y =
      ((Math.sin(rad) * delta) / currentRatio) * nextRatio + centerY;

    const cw = (this.crop.width / currentRatio) * nextRatio;
    const ch = (this.crop.height / currentRatio) * nextRatio;
    this.crop.width = ch;
    this.crop.height = cw;
    this.props.setInitialBound(
      0,
      0,
      this.props.initialBound.height,
      this.props.initialBound.width,
    );
    this.refreshCrop();

    this.props.setRotationIndex(this.rotationIndex);
    setTimeout(() => {
      this.applyCrop();
    }, 0);
  };

  render() {
    return (
      <>
        <div className="top-toolbar">
          <div className="right-side">
            <IconButton icon={IconTypes.CLOSE()} onClick={this.props.onQuit} />
          </div>

          <div className="center">
            <IconButton
              isSelected
              icon={IconTypes.CROP("selected")}
              onClick={this.handleValidateCrop}
            />
            <IconButton icon={IconTypes.ROTATE()} onClick={this.handleRotate} />
          </div>

          <div className="left-side">
            <IconButton
              icon={IconTypes.VALIDATE()}
              onClick={this.handleValidateCrop}
            />
          </div>
        </div>

        <div className="crop-container">
          <div className="crop-content">
            <div className="corner top left" />
            <div className="corner top right" />
            <div className="corner bottom left" />
            <div className="corner bottom right" />
            <div className="side top" />
            <div className="side right" />
            <div className="side bottom" />
            <div className="side left" />
          </div>
        </div>
      </>
    );
  }
}

const mapSelectors = (state) => ({
  cropBound: CropEditorSelectors.bound(state),
  initialBound: CropEditorSelectors.initialBound(state),
  rotationIndex: CropEditorSelectors.rotationIndex(state),
});

const mapActions = (dispatch) => ({
  useText: () => dispatch(ToolsActions.useText()),
  useDraw: () => dispatch(ToolsActions.useDraw()),
  clearPrimaryTool: () => dispatch(ToolsActions.clearPrimaryTool()),
  setEditMode: (isInEditMode) =>
    dispatch(CropEditorActions.setEditMode(isInEditMode)),
  setInitialBound: (x, y, width, height, scale) =>
    dispatch(CropEditorActions.setInitialBound(x, y, width, height, scale)),
  setBound: (x, y, width, height, scale) =>
    dispatch(CropEditorActions.setBound(x, y, width, height, scale)),
  setRotationIndex: (rotationIndex) =>
    dispatch(CropEditorActions.setRotationIndex(rotationIndex)),
});

export default connect(mapSelectors, mapActions)(CropToolbar);
