import React from "react";
import Hammer from "hammerjs";
import { connect } from "react-redux";
import * as PIXI from "pixi.js-legacy";
import PixiApp from "../../../../PixiApp";
import ColorSwiper from "../../../ColorSwiper/ColorSwiper";
import config from "../../../../config";
import {
  actions as DrawEditorActions,
  selectors as DrawEditorSelectors,
} from "../../../../redux/drawEditor/redux";
import { selectors as CropEditorSelectors } from "../../../../redux/cropEditor/redux";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import {
  actions as ToolsActions,
  selectors as ToolsSelectors,
} from "../../../../redux/tools/redux";
import Icon from "../../../Icon/Icon";
import IconButton from "../../../IconButton/IconButton";
import IconTypes from "../../../Icon/Types";
import * as utils from "../../../../pages/EditorPage/utils";
import HistoryItem from "./partial/HistoryItem";
const MAX_WIDTH = 30;
const MIN_WIDTH = 3;

class DrawToolbar extends React.PureComponent {
  path = [];
  mounted = true;
  offset = { x: 0, y: 0 };
  lastHistoryItem = null;

  componentDidMount() {
    const canvas = document.querySelector("#canvas");
    this.mc = new Hammer.Manager(canvas);
    this.mc.add(new Hammer.Tap());
    this.mc.on("tap", this.handleDrawPoint);
    this.mc.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
    this.mc.on("panstart", this.handleDrawStart);
    this.mc.on("panmove", this.handleDrawMove);
    this.mc.on("panend", this.handleDrawEnd);
    this.mc.on("pancancel", this.handlePanCancel);

    this.offset.x = -canvas.getBoundingClientRect().left;
    this.offset.y = -canvas.getBoundingClientRect().top;

    if (PixiApp.drawContainer.children.length === 1) {
      this.renderTexture = PixiApp.drawContainer.children[0].texture;
      this.tempContext = PixiApp.drawContainer.children[0].tempContext;
      this.tempCanvas = PixiApp.drawContainer.children[0].tempCanvas;
    } else {
      this.tempCanvas = document.createElement("canvas");
      this.tempCanvas.width =
        PixiApp.backgroundContainer.width * PIXI.settings.RESOLUTION; // sourceCanvas.width
      this.tempCanvas.height =
        PixiApp.backgroundContainer.height * PIXI.settings.RESOLUTION; //sourceCanvas.height
      this.tempCanvas.style.position = "absolute";
      this.tempCanvas.style.top = 0;
      this.tempCanvas.style.left = 0;
      this.tempCanvas.style.width = window.innerWidth + "px";
      this.tempCanvas.style.height = window.innerHeight + "px";

      this.tempContext = this.tempCanvas.getContext("2d");

      this.renderTexture = PIXI.Texture.from(this.tempCanvas, {
        resolution: PIXI.settings.RESOLUTION,
      });
      const sprite = new PIXI.Sprite(this.renderTexture);
      sprite.tempContext = this.tempContext;
      sprite.tempCanvas = this.tempCanvas;
      PixiApp.drawContainer.addChild(sprite);
      this.spriteId = PixiApp.registerObject(sprite);
    }

    this.handleChangeWidth(this.props.width);
  }

  handleDrawStart = (evt) => {
    this.path = new Path2D();

    const lineWidth =
      (this.props.width * PIXI.settings.RESOLUTION) /
      PixiApp.cropContainer.scale.x /
      PixiApp.zoomContainer.scale.x;
    const strokeStyle = config.colors[this.props.colorIndex];
    this.lastHistoryItem = new HistoryItem(strokeStyle, lineWidth);
    //this.history.push(this.lastHistoryItem)

    this.lastHistoryItem.beginPath(this.tempContext);

    const [px, py] = utils.screenToCrop(
      this.props.cropBound,
      evt.center.x,
      evt.center.y,
    );
    this.lastHistoryItem.moveTo(
      px * PIXI.settings.RESOLUTION,
      py * PIXI.settings.RESOLUTION,
      this.tempContext,
    );
    //this.lastHistoryItem.path.moveTo(px*PIXI.settings.RESOLUTION,py*PIXI.settings.RESOLUTION)
  };

  handleDrawPoint = (evt) => {
    this.handleDrawStart(evt);
    evt.center.x += 1;
    this.handleDrawMove(evt);
    this.handleDrawEnd(evt);
  };

  handlePanCancel = (evt) => {
    console.log("pan canceled");
    //const sprite = PixiApp.getObject(this.spriteId)
    //PixiApp.drawContainer.removeChild(sprite)
    //PixiApp.remove(this.spriteId)
  };

  handleDrawMove = (evt) => {
    const [px, py] = utils.screenToCrop(
      this.props.cropBound,
      evt.center.x,
      evt.center.y,
    );

    this.lastHistoryItem.lineTo(
      px * PIXI.settings.RESOLUTION,
      py * PIXI.settings.RESOLUTION,
      this.tempContext,
    );

    //this.lastHistoryItem.path.lineTo(, )
    //this.tempContext.clearRect(0,0,this.tempCanvas.width, this.tempCanvas.height)
    // this.tempContext.stroke(this.lastHistoryItem.path)
    this.renderTexture.baseTexture.update();
  };

  handleDrawEnd = (evt) => {
    this.lastHistoryItem.end(this.tempContext);
    // console.log('x',this.props.cropBound.x*PIXI.settings.RESOLUTION)
    // console.log('y',this.props.cropBound.y*PIXI.settings.RESOLUTION)
    // console.log('width',this.props.cropBound.width*PIXI.settings.RESOLUTION)
    // console.log('height',this.props.cropBound.height*PIXI.settings.RESOLUTION)
    // console.log(this.lastHistoryItem.data)
    const viewBound = {
      left: this.props.cropBound.x * PIXI.settings.RESOLUTION,
      top: this.props.cropBound.y * PIXI.settings.RESOLUTION,
      right:
        this.props.cropBound.x * PIXI.settings.RESOLUTION +
        this.props.cropBound.width * PIXI.settings.RESOLUTION,
      bottom:
        this.props.cropBound.y * PIXI.settings.RESOLUTION +
        this.props.cropBound.height * PIXI.settings.RESOLUTION,
    };
    let margin = 20;
    let isInViewBound =
      this.lastHistoryItem.data.find((step) => {
        return (
          step.x > viewBound.left - margin &&
          step.x < viewBound.right + margin &&
          step.y > viewBound.top - margin &&
          step.y < viewBound.bottom + margin
        );
      }) != undefined;

    console.log(isInViewBound);
    if (!isInViewBound) {
      if (this.props.history.length) {
        this.props.history.forEach((step, index) => {
          let historyItem = HistoryItem.deserialize(step);
          historyItem.drawAll(this.tempContext);
        });
        this.renderTexture.baseTexture.update();
      }

      return;
    }

    this.props.addToHistory(this.lastHistoryItem.serialize());
  };

  componentWillUnmount() {
    this.mounted = false;
    this.mc.destroy();
  }

  handleChangeColor = (colorIndex) => {
    this.props.setColorIndex(colorIndex);
  };

  handleChangeWidth = (v) => {
    const handle = document.querySelector(".rangeslider__handle");
    if (handle) {
      const size = v * 0.4 + 30;
      handle.style.width = size + "px";
      handle.style.height = size + "px";
      handle.style.borderRadius = size / 2 + "px";
      handle.style.left = (v - MIN_WIDTH) * -0.2 - 15 + "px";
    }
    this.props.setWidth(v);
  };

  handleUndo = () => {
    console.log(this.props.history.length);
    if (this.props.history.length === 0) return;
    this.tempContext.clearRect(
      0,
      0,
      this.tempCanvas.width,
      this.tempCanvas.height,
    );

    //const lastId = this.props.lastInHistory
    //if(lastId !== null) {

    this.props.history.forEach((step, index) => {
      let historyItem = HistoryItem.deserialize(step);
      if (index < this.props.history.length - 1)
        historyItem.drawAll(this.tempContext);
    });
    this.renderTexture.baseTexture.update();
    //const sprite = PixiApp.getObject(lastId)
    //PixiApp.drawContainer.removeChild(sprite)
    //PixiApp.removeObject(lastId)
    this.props.historyBack();
    // }
  };

  handleValidate = () => {
    this.props.clearPrimaryTool();
  };

  render() {
    return (
      <>
        <div className="top-toolbar">
          <div className="right-side">
            <IconButton icon={IconTypes.CLOSE()} onClick={this.props.onQuit} />
          </div>

          <div className="center">
            <IconButton
              isSelected
              icon={IconTypes.DRAW_BASIC("selected")}
              onClick={this.props.clearPrimaryTool}
            />
            <IconButton icon={IconTypes.TEXT()} onClick={this.props.useText} />
            <IconButton icon={IconTypes.CROP()} onClick={this.props.useCrop} />
          </div>

          <div className="left-side">
            <IconButton
              icon={IconTypes.VALIDATE()}
              onClick={this.props.onSave}
            />
          </div>
        </div>
        {this.props.lastInHistory && (
          <div className="undo-draw">
            <IconButton icon={IconTypes.UNDO()} onClick={this.handleUndo} />
          </div>
        )}

        <div className="stroke-width-container">
          <div className="stroke-width-wrapper">
            <div className="stroke-width-background">
              <img src={require("./svg/stroke-width.svg")} />
            </div>
            <div className="slider-container">
              <Slider
                value={this.props.width}
                orientation="vertical"
                onChange={this.handleChangeWidth}
                min={MIN_WIDTH}
                max={MAX_WIDTH}
                tooltip={false}
              />
            </div>
          </div>
        </div>

        <div className="bottom-toolbar">
          <ColorSwiper
            onChange={this.handleChangeColor}
            colors={config.colors}
            colorsByPage={7}
            selectedColor={this.props.colorIndex}
          />
        </div>
      </>
    );
  }
}

const mapSelectors = (state) => ({
  width: DrawEditorSelectors.width(state),
  colorIndex: DrawEditorSelectors.colorIndex(state),
  lastInHistory: DrawEditorSelectors.lastInHistory(state),
  history: DrawEditorSelectors.history(state),
  cropBound: CropEditorSelectors.bound(state),
});

const mapActions = (dispatch) => ({
  setColorIndex: (index) => dispatch(DrawEditorActions.setColorIndex(index)),
  setWidth: (width) => dispatch(DrawEditorActions.setWidth(width)),
  addToHistory: (spriteId) =>
    dispatch(DrawEditorActions.addToHistory(spriteId)),
  historyBack: () => dispatch(DrawEditorActions.historyBack()),
  clearPrimaryTool: () => dispatch(ToolsActions.clearPrimaryTool()),
  useText: () => dispatch(ToolsActions.useText()),
  useCrop: () => dispatch(ToolsActions.useCrop()),
});

export default connect(mapSelectors, mapActions)(DrawToolbar);
