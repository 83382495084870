import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  primaryTool: "manipulation",
  editingDisplayId: null,
});

export const actions = {
  edit: createAction("set toolbar", (toolbar, editingDisplayId) => ({
    toolbar,
    editingDisplayId,
  })),
  clearPrimaryTool: createAction("clear primary tool"),
  useText: createAction("use text"),
  useDraw: createAction("use draw"),
  useTag: createAction("use tag"),
  useCrop: createAction("use crop"),
};

export const reducer = createReducer(
  {
    [actions.edit]: (state, { toolbar, editingDisplayId }) =>
      state.merge({ primaryTool: toolbar, editingDisplayId }),
    [actions.clearPrimaryTool]: (state) =>
      state.merge({ primaryTool: "manipulation", editingDisplayId: null }),
    [actions.useText]: (state) =>
      state.merge({ primaryTool: "text", editingDisplayId: null }),
    [actions.useDraw]: (state) =>
      state.merge({ primaryTool: "draw", editingDisplayId: null }),
    [actions.useTag]: (state) =>
      state.merge({ primaryTool: "tag", editingDisplayId: null }),
    [actions.useCrop]: (state) =>
      state.merge({ primaryTool: "crop", editingDisplayId: null }),
  },
  initialState,
);

export const selectors = {
  primaryTool: (state) => state.tools.primaryTool,
  editingDisplayId: (state) => state.tools.editingDisplayId,
};
