export default [
  "#FFFFFF",
  "#000000",
  "#5CB6FF",
  "#0FD100",
  "#FFD250",
  "#FF5407",
  "#E70000",
  "#AD00E9",

  "#FF6D6D",
  "#FEBDBD",
  "#FFE3D4",
  "#FFDFAF",
  "#FFBF7D",
  "#D6905B",
  "#BA6C31",
  "#AD00E9",

  "#0F8426",
  "#3DBB52",
  "#86D893",
  "#5DC8A1",
  "#3D7BBB",
  "#2A7196",
  "#0E5871",
  "#0E2771",
];
