import { createAction, createReducer } from "redux-act";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  items: {},
});

export const actions = {
  addItem: createAction("add item", (id, meta) => ({ id, meta })),
  removeItem: createAction("remove item", (id) => ({ id })),
  updateMeta: createAction("update meta", (id, meta) => ({ id, meta })),
};

export const reducer = createReducer(
  {
    [actions.addItem]: (state, { id, meta }) =>
      state.setIn(["items", id], meta),
    [actions.removeItem]: (state, { id }) =>
      state.updateIn(["items"], (items) =>
        items.without((value, key) => key === id),
      ),
    [actions.updateMeta]: (state, { id, meta }) => {
      const source = state.getIn(["items", id]).asMutable();
      return state.setIn(["items", id], { ...source, ...meta });
    },
  },
  initialState,
);

export const selectors = {
  items: (state) => state.display.items,
  item: (state, id) => state.display.items[id],
};
