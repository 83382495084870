import React from "react";
import Swiper from "swiper";
import "swiper/css/swiper.css";

export default class ColorSwiper extends React.Component {
  componentDidMount() {
    this.swiper = new Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
      },
    });
  }

  groupBy(count) {
    return (result, color, index) => {
      let last = result[result.length - 1];
      if (last.length >= count) {
        last = [];
        result.push(last);
      }
      last.push({ index: index, color: color });
      return result;
    };
  }

  render() {
    return (
      <div className="color-swiper">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {this.props.colors
              .reduce(this.groupBy(this.props.colorsByPage + 1), [[]])
              .map((group) => {
                return (
                  <div className="swiper-slide">
                    {group.map((item) => {
                      const className =
                        item.index === this.props.selectedColor
                          ? "color-item-selected"
                          : "color-item";
                      return (
                        <div
                          key={item.index}
                          onClick={() => this.props.onChange(item.index)}
                          className={className}
                          style={{ backgroundColor: item.color }}
                        ></div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="pagination-container">
          <div className="swiper-pagination"></div>
        </div>
      </div>
    );
  }
}
