import React from "react";
import classnames from "classnames";

const Icon = ({ icon, className }) => {
  return (
    <div className="icon">
      <img src={icon} alt="icon" />
    </div>
  );
};

export default Icon;
