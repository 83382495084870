import * as PIXI from "pixi.js-legacy";

export default class PixiApp {
  static FULL_VIEWPORT = {
    x: 0,
    y: 0,
    width: window.innerWidth,
    height: window.innerHeight,
  };

  static CROP_VIEWPORT = {
    x: 20,
    y: 70,
    width: window.innerWidth - 40,
    height: window.innerHeight - 90,
  };

  static zoomContainer = null;
  static maskContainer = null;
  static cropContainer = null;
  static backgroundContainer = null;

  static overlayContainer = null;
  static stage = null;
  static drawContainer = null;
  static app = null;
  static registry = {};
  static __ID = 1;
  static bound = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  };

  static init(app) {
    PixiApp.app = app;

    //zoom and pan
    const zoomContainer = new PIXI.Container();
    app.stage.addChild(zoomContainer);
    PixiApp.zoomContainer = zoomContainer;

    //sert a afficher les rectangles noir
    const maskCrop = new PIXI.Container();
    zoomContainer.addChild(maskCrop);
    PixiApp.maskContainer = maskCrop;

    //sert à déplacer le contenu dans le mask
    const cropContainer = new PIXI.Container();
    maskCrop.addChild(cropContainer);
    PixiApp.cropContainer = cropContainer;

    const backgroundContainer = new PIXI.Container();
    cropContainer.addChild(backgroundContainer);
    PixiApp.backgroundContainer = backgroundContainer;

    const drawContainer = new PIXI.Container();
    cropContainer.addChild(drawContainer);
    PixiApp.drawContainer = drawContainer;

    const stage = new PIXI.Container();
    stage.sortableChildren = true;
    cropContainer.addChild(stage);
    PixiApp.stage = stage;

    const overlayContainer = new PIXI.Container();
    app.stage.addChild(overlayContainer);
    PixiApp.overlayContainer = overlayContainer;
  }

  /*
  static init(app,zoomContainer, stage, drawContainer) {
    PixiApp.app = app
    PixiApp.drawContainer = drawContainer
    PixiApp.stage = stage
    PixiApp.zoomContainer = zoomContainer
  }
  */

  static registerObject(display) {
    const id = ++PixiApp.__ID;
    PixiApp.registry[id] = display;
    return id;
  }

  static getObject(id) {
    return PixiApp.registry[id];
  }

  static getId(object) {
    for (var i in PixiApp.registry) {
      if (PixiApp.registry[i] === object) return i;
    }
    return null;
  }

  static removeObject(id) {
    delete PixiApp.registry[id];
  }

  static getIds() {
    return Object.keys(PixiApp.registry);
  }
}
