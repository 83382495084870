import PixiApp from "../../PixiApp";

export const calculateScreenBound = (sourceBound) => {
  const ww = window.innerWidth;
  const wh = window.innerHeight;
  const bw = sourceBound.width;
  const bh = sourceBound.height;

  const ratioW = ww / bw;
  const ratioH = wh / bh;
  const ratio = Math.min(ratioW, ratioH);

  const finalBW = bw * ratio;
  const finalBH = bh * ratio;
  const finalX = ww / 2 - finalBW / 2;
  const finalY = wh / 2 - finalBH / 2;

  /*
  return {
    ...sourceBound,
    scale:ratio
  }
  */

  return {
    x: finalX,
    y: finalY,
    width: finalBW,
    height: finalBH,
    scale: ratio,
  };
};

export const screenToCrop = (cropBound, screenX, screenY) => {
  const scale = PixiApp.zoomContainer.scale.x;
  screenX -= PixiApp.zoomContainer.x;
  screenY -= PixiApp.zoomContainer.y;

  screenX /= scale;
  screenY /= scale;

  const viewport = PixiApp.FULL_VIEWPORT;

  const ratio = Math.min(
    viewport.width / cropBound.width,
    viewport.height / cropBound.height,
  );

  const top = viewport.height / 2 - (cropBound.height * ratio) / 2 + viewport.y;

  const left = viewport.width / 2 - (cropBound.width * ratio) / 2 + viewport.x;

  const x = screenX / ratio + cropBound.x - left / ratio;
  const y = screenY / ratio + cropBound.y - top / ratio;

  return [x, y];
};

export const XXscreenToCrop = (sourceBound, screenX, screenY) => {
  const ww = window.innerWidth;
  const wh = window.innerHeight;
  const bw = sourceBound.width;
  const bh = sourceBound.height;

  const ratioW = ww / bw;
  const ratioH = wh / bh;
  let ratio = Math.min(ratioW, ratioH);

  const finalBW = bw * ratio;
  const finalBH = bh * ratio;
  const finalX = ww / 2 - finalBW / 2;
  const finalY = wh / 2 - finalBH / 2;
  const scale = PixiApp.zoomContainer.scale.x;
  screenX -= PixiApp.zoomContainer.x;
  screenY -= PixiApp.zoomContainer.y;

  screenX /= scale;
  screenY /= scale;

  let px = (screenX - finalX) / ratio + sourceBound.x;
  let py = (screenY - finalY) / ratio + sourceBound.y;

  //px/=PixiApp.zoomContainer.scale.x
  //py/=PixiApp.zoomContainer.scale.x
  //px=px/PixiApp.zoomContainer.scale.x
  //py=py/PixiApp.zoomContainer.scale.y
  return [px, py];
};

export const calculateImageCrop = (sourceBound, imageSize) => {
  const ww = window.innerWidth;
  const wh = window.innerHeight;
  const bw = sourceBound.width;
  const bh = sourceBound.height;

  const ratioW = ww / bw;
  const ratioH = wh / bh;
  const ratio = Math.min(ratioW, ratioH);
  return {
    scale: ratio,
  };
};
